<template>
    <div class="loginBackground">
      <div class="loginLogo">
        <img src="../assets/uclogo_white.png" alt="" />
      </div>
      <modal-terms-and-condition />
      <modal-privacy-policy />
      <div class="login-card">
        <div class="login-form">
          <div class="form-group">
            <div class="input-lbl">
              <label for="user_login">Username or Email Address</label>
            </div>
            <b-form-input
              type="text"
              class="form-control"
              id="user_login"
              v-model="user_login"
              size="sm"
              @change="agreeTerm"
            />
          </div>
          <div class="form-group">
            <div class="input-lbl">
              <label for="password">Password</label>
            </div>
            <b-form-input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              size="sm"
            />
          </div>
          <div
            v-if="isTermsAgree !== undefined && isTermsAgree == false"
            class="form-group"
            style="text-align: start"
          >
            <b-form-checkbox
              id="checkbox-1"
              v-model="terms_agreed"
              name="agreed_terms"
              unchecked-value="false"
              checked-value="true"
            >
              I agree to the
              <b-link
                v-b-modal.terms-and-condition-modal
                >University Credit Terms</b-link
              >
            </b-form-checkbox>
          </div>
          <div class="card-actions">
            <b-button
              size="sm"
              type="submit"
              class="btn btn-primary"
              @click="login"
            >
              Log In
            </b-button>
          </div>
        </div>
      </div>
      <a
        class="text-sm-left mt-2 text-white d-flex"
        style="cursor: pointer"
        @click="redirectForgotPassword"
        >Forgot password?</a
      >
      <span class="footer">
        <b-link v-b-modal.terms-and-condition-modal>TERMS & CONDITIONS</b-link > |
        <b-link v-b-modal.privacy-policy-modal>PRIVACY POLICY</b-link><br>
        <span class="copyright">©2022 University Credit™ - CECG. All Rights Reserved</span>
      </span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalTermsAndCondition from "../components/Molecules/ModalTermsAndCondition.vue";
import ModalPrivacyPolicy from "../components/Molecules/ModalPrivacyPolicy.vue";
export default {
  components: { 
    ModalTermsAndCondition,
    ModalPrivacyPolicy,
  },
  data: () => {
    return {
      password: "",
      user_login: "",
      confirm_password: "",
      errorMessage: "",
      terms_agreed: false,
    };
  },
  methods: {
    redirectForgotPassword() {
      this.$router.push("/forgot_pass");
    },
    login() {
      this.errorMessage = "";
      
      this.$store
        .dispatch("login", {
          user_login: this.user_login,
          pass: this.password,
          agreed_terms: this.terms_agreed,
        })
        .then(() => {
          var pushObject = {path: "/dashboard"}
          if(this.$route.query) pushObject.query = this.$route.query
          this.$router.push(pushObject)
        })
        .catch((err) => {
          this.makeToast({
            message: err,
            variant: "danger",
          });
        });
    },
    agreeTerm() {
      if (this.user_login !== "") {
        this.$store.dispatch("isAgreedTerms", { user_login: this.user_login });
      }
    },
    //displayTermsModal() {},
    //displayPrivacyModal() {},
  },
  computed: {
    ...mapGetters(["isTermsAgree"]),
  },
};
</script>
<style scoped>
.footer {
  color: white;
  position: absolute;
  bottom: 30px;
}
.footer a {
  color: white;
}
.footer .copyright {
  margin-top: 0.2rem;
  font-size: 0.9rem;

}
.footer svg {
  margin-left: 0.4rem;
  margin-right: 0.6rem;
}
</style>