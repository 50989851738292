<template >
  <b-modal
    id="terms-and-condition-modal"
    centered
    class="termsModal"
    scrollable
    size="lg"
    title="Terms and Conditions"
    cancel-disabled
    ok-disabled
  >
    <template>
      <div class="modalContent">
        <span class="heading"> Overview </span>
        <span class="description">
          These Website Standard Terms and Conditions contained herein on this
          webpage shall govern your use of this website, including all pages within this
          website (collectively referred to herein below as this “Website”). California
          Educational Consultant Group, Inc. (CECG), doing business as “University
          Credit™” (“We”, “Us, or “Our”) is committed to the providing our website
          visitors (“you”) with defined terms and conditions when using our services.
          These Terms apply in full force and effect to you, a natural person or a
          designated agent of an organization, corporation, company, partnership or
          other legal entity (“You”). Use of this Website and by using this Website, you
          expressly accept all terms and conditions contained herein in full. You must
          not use this Website if you have any objection to any of these Website
          Standard Terms and Conditions. 
        </span>
        <span class="heading"> Intellectual Property Rights </span>
        <span class="description">
          Other than the content you own, which you may have opted to include on
          this Website, under these Terms, University Credit™ - CECG licensors own all
          rights to the intellectual property and material contained in this Website, and
          all such rights are reserved. You are granted a limited license only, subject to
          the restrictions provided in these Terms, for purposes of viewing the material
          contained on this Website.
        </span>
        <span class="heading"> Restrictions </span>
        <span class="description">
          You are expressly and emphatically restricted from all of the following:
        </span>
        <div class="list-container">
          <li>
            publishing any Website material in any media without consent/approval from this Website
          </li>
          <li>
           selling, sublicensing, and/or otherwise commercializing any Website material
          </li>
          <li>
            publicly performing and/or showing any Website material without consent/approval from this Website
          </li>
          <li>
            using this Website in any way that is, or may be, damaging to this Website
          </li>
          <li>
            using this Website in any way that impacts user access to this Website
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or in a way 
            that causes, or may cause, harm to the Website or to any person or business entity
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity relating to this Website, or while using this Website
          </li>
          <li>using this Website to engage in any advertising or marketin</li>
        </div>
        <span class="description">
          By using our Services you confirm that you and your company are not listed
          on any U.S. Government list of prohibited or restricted parties. Certain areas
          of this Website are restricted from access by you, and University Credit™ -
          CECG may further restrict access by you to any areas of this Website, at any
          time, in its sole and absolute discretion. Any user ID and password you may
          have for this Website are confidential and you must maintain the
          confidentiality of such information.
        </span>
        <span class="description">
          Before allowing you to use our Services, we need to verify you, your business
          information and other necessary information as part of the accreditation
          process at University of the Pacific, Benerd College. In order to make full use
          of the Services, you must be assigned a University Credit™ - CECG online
          account. During this custom sign up process with your University Credit™ -
          CECG representative you agree to:
        </span>
        <div class="list-container">
          <li>
            Update your University Credit™ - CECG account information as necessary
          </li>
          <li>Secure your account and not share your password</li>
          <li>
           Promptly notify University Credit™ - CECG if you identify any security
          threats or issues related to the Services we provide
          </li>
        </div>
        <span class="description">
          Further, you authorize us to make any inquiries necessary to verify your
          identify prior to provisioning your University Credit™ - CECG Account.
          Verification may include:
        </span>
        <div class="list-container">
          <li>Asking you for additional information</li>
          <li>Requiring you to provide your full name and address</li>
          <li>
            Requiring your company to confirm your employment and obtaining their
            authorization to provide you with access to University Credit™ - CECG
            Services on their behalf
          </li>
          <li>Requiring you to verify your email or mobile phone number</li>
          <li>
            Checking your information against third party databases or other
            sources
          </li>
        </div>
        <span class="heading">Security</span>
        <span class="description">
          While we maintain technical and organizational safeguard to secure your
          sensitive information from loss, unauthorized use, unauthorized access, and
          disclosure, you are responsible for restricting access to your Account and
          protecting your login, password, and the device you utilize for multi-factor
          authentication. Website is provided “as is,” with all faults, and University
          Credit™ - CECG makes no express or implied representations or warranties,
          of any kind related to this Website or the materials contained on this
          Website.
        </span>
        <span class="description">
          Immediately notify University Credit™ - CECG of any unauthorized use of
          your University Credit™Account, your password or any breach of security and
          we will take prompt option to limit to prevent further loss and investigate.
        </span>
        <span class="heading"> Your Data</span>
        <span class="description">
          Data you utilize University Credit™ - CECG Services to store, transmit, receive, 
          enrich or otherwise secure. You own your data.
        </span>
        <span class="heading"> License of University Credit™ - CECG Service </span>
        <span class="description">
          University Credit™ - CECG grants you a limited, non-exclusive, sublicensable, 
          revocable license to use the Services as authorized in this Agreement and other 
          Agreements between you and University Credit™ -CECG.
        </span>
        <span class="heading"> No Warranties</span>
        <span class="description">
          This Website is provided “as is,” with all faults, and University Credit™ -
          CECG makes no express or implied representations or warranties, of any kind
          related to this Website or the materials contained on this Website.
          Additionally, nothing contained on this Website shall be construed as
          providing consult or advice to you. 
        </span>
        <span class="heading"> Limitation of Liability </span>
        <span class="description">
          In no event shall University Credit™ - CECG nor any of its officers, directors,
          and employees, be liable to you for anything arising out of or in any way
          connected with your use of this Website, whether such liability is under
          contract, tort or otherwise, and University Credit™ - CECG, including its
          officers, directors, and employees shall not be liable for any indirect,
          consequential or special liability arising out of or in any way related to your
          use of this Website.
        </span>
        <span class="heading"> Indemnification </span>
        <span class="description">
          You hereby indemnify to the fullest extent University Credit™ - CECG from
          and against any liabilities, costs, demands, causes of action, damages, and
          expenses (including reasonable attorney’s fees) arising out of or in any way
          related to your breach of any of the provisions of these Terms.
        </span>
        <span class="heading"> Conflict with other agreements: </span>
        <span class="description">
          If there are portions of this agreement that conflict with a pre-existing
          agreement between you or your company and University Credit™ - CECG,
          the terms of the pre-existing agreement will take precedence solely for the
          duration of that agreement.
        </span>
        <span class="heading"> Severability </span>
        <span class="description">
          If any provision of these Terms is found to be unenforceable or invalid under
          any applicable law, such unenforceability or invalidity shall not render these
          Terms unenforceable or invalid as a whole, and such provisions shall be
          deleted without affecting the remaining provisions herein.
        </span>
        <span class="heading"> Data Security and Privacy </span>
        <div class="list-container">
          <li>
            Confidentiality: Data that you use with University Credit™ - CECG Services
            to send, secure, or receive remains your data. University Credit™ -
            CECG will maintain your data as your confidential information and only
            use this data as permitted by this Agreement or other agreements
            between your or your company and University Credit™ - CECG.
          </li>

          <li>
            Data you receive through or about University Credit™ - CECG Services 
            remains CECGs’ information unless it:
            <ul>
              <li class="list-style-circle">
                It becomes through no malicious or improper action or inaction by
                you or your company generally available to the public
              </li>
              <li class="list-style-circle">
                Was in your possession or known by you without restriction prior to
                communication with University Credit™ - CECG
              </li>
              <li class="list-style-circle">
                Was independently developed without use of any confidential
                University Credit™ - CECG information
              </li>
            </ul>
          </li>
          <li>
            Rights Granted to University Credit™ - CECG you agree upon when using this site:
            <ul>
              <li class="list-style-circle">
                University Credit™ - CECG takes no ownership of your company data
              </li>
              <li class="list-style-circle">
                licensing the content you produce for the purpose of providing
                University Credit™ - CECG Services
              </li>
            </ul>
          </li>
          <li>
            University Credit™ - California Educational Consultant Group, Inc. (CECG),
            it’s subsidiaries and partners collect cardholder data (credit and debit
            cards) through e-commerce platforms. Card Holder Data is securely
            processed, stored and disposed by CECG for payment transactions at
            University of the Pacific, Benerd College. University Credit™ - CECG
            adheres to the Payment Card Industry Data Security Standards
            (PCIDSS). For more information contact admin@universitycredit.com.
          </li>
        </div>
        <span class="heading"> Variation of Terms </span>
        <span class="description">
          We may update our Services at any time. Any updates implemented may
          add new features or remove existing features and settings for workflows that
          you utilize on our platforms. University Credit™ - CECG is permitted to revise
          these Terms at any time as it sees fit, and by using this Website you are
          expected to review such Terms regularly to ensure you understand all terms
          and conditions governing the use of this Website.We may also change the
          terms in this Agreement and any additional Terms or Policies at any time. If
          you do not agree to the revised Agreement do not continue using our
          Services. Any dispute will be governed by the version of the Agreement in
          place at the time of the dispute. For updates to services or revisions to our
          Agreements we will provide any notice we find reasonable by either linking
          the revised version of the Agreement to your University Credit™ - CECG
          dashboard or via email.
        </span>
        <span class="heading"> Assignment </span>
        <span class="description">
          University Credit™ - CECG shall be permitted to assign, transfer, and
          subcontract its rights and/or obligations under these Terms without any
          notification or consent required. However, you shall not be permitted to
          assign, transfer or subcontract any of your rights and/or obligations under
          these Terms.
        </span>
        <span class="heading"> Entire Agreement </span>
        <span class="description">
          These Terms, including any legal notices and disclaimers contained on this
          Website, constitute the entire agreement between University Credit™ - CECG
          and you concerning your use of this Website and supersede all prior
          agreements and understandings concerning the same.
        </span>
        <span class="heading"> Governing Law & Jurisdiction </span>
        <span class="description">
          These Terms will be governed by and construed in accordance with the laws
          of the State of California, and you submit to the non-exclusive jurisdiction of
          the state and federal courts located in California for the resolution of any
          disputes.
        </span>
      </div>
    </template>
    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button variant="success" @click="cancel"> Close </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  // watch: {
  //   stage(newVal) {
  //     if (newVal === "success" || newVal === "error") {
  //       this.$emit("closeDialog");
  //       this.$root.$emit("bv::hide::modal", "terms-and-condition-modal");
  //     }
  //   },
  // }
};
</script>


<style scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.list-style-circle {
  list-style-type: circle;
}
.heading {
  font-size: 18px;
  font-weight: 500;
}
.heading--big {
  font-size: 26px;
  font-weight: bold;
}
.description {
  font-size: 16px;
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.list-container {
  padding: 10px 20px;
  font-size: 16px;
}
.footer {
  text-align: center;
}
</style>