<template >
  <b-modal
    id="privacy-policy-modal"
    centered
    class="privacyModal"
    scrollable
    size="lg"
    title="Privacy Policy"
    cancel-disabled
    ok-disabled
  >
    <template>
      <div class="modalContent">
        <span class="heading"> Overview </span>
        <span class="description">
          California Educational Consultant Group, Inc., also known as “University
          Credit™” (“We”, “Us, or “Our”) is committed to the privacy of our website
          visitors (“you”). This policy refers to the gathering and dissemination
          practices to operate www.universitycredit.com (the “Site”) in providing
          products and services to you. If for any reason there are terms in this privacy
          policy that you do not agree with, please discontinue any use of our Sites
          and services.
        </span>
        <span class="heading"> What data does University Credit™ collect? </span>
        <span class="description"> Customer information: </span>
        <div class="list-container">
          <li>Company Name</li>
          <li>Company Contact Name(s)</li>
          <li>Business details</li>
          <li>Business address</li>
          <li>Business email address</li>
          <li>Business telephone number</li>
          <li>University Credit™ username and password</li>
          <li>
            Other identifying information that you choose to share with us
          </li>
        </div>
        <span class="description">
          We collect personally identifiable information and behavioral
          information in order to provide services to businesses. All personal
          and business information collected serves as part of the accreditation
          process. Behavioral information that we may collect includes the name
          of the domain and host from which you are accessing the Internet, the
          IP address/operating system and browser of the computer being used,
          the time and date of accessing the Site, the internet address if
          available from which you linked the Site, the number of links clicked
          and pages viewed within the Site, and the searches you conducted via
          the Site.
        </span>
        <span class="description">
          “Cookies” are used to collect this information automatically. They are
          small text files which are sent to your computer to collect and
          transmit the information mentioned above back to our Site whenever
          your browser requests a page from the Site. The information is stored
          to help you navigate the use of the Site on subsequent visits.
        </span>

        <span class="heading">How does University Credit™ collect your data?</span>
        <span class="description"> University Credit™ collects your data when you: </span>
        <div class="list-container">
          <li>
            Create a University Credit™ account online or conduct business
            with us for processing orders with University of the Pacific
          </li>
          <li>Request information on University Credit™ and its products and services</li>
          <li>Subscribe to our services or receive consultations</li>
          <li>Sign up for a University Credit™ webinar</li>
          <li>
            Voluntarily complete a customer survey or provide feedback to us via
            email
          </li>
        </div>
        <span class="description">
          Your business information you submit to us for product services and
          products will be used to process the order request. We may use this
          information to respond to inquiries and to keep you abreast of
          services, products and updates relating to your orders. We may also
          contact you to improve the Site, our products, and services. There may
          be cases where we monitor IP addresses and other behavioral
          information. This is solely for security reasons, Site administration,
          fraud prevention and analysis, troubleshooting problems, and service
          enhancement. To “opt-out” of our email and other messages or from our
          use of cookies, submit a request to security@universitycredit.com.
          Another option is to set your browser to not accept cookies from this
          Site.
        </span>
        <span class="heading"> How does University Credit™ use your data? </span>

        <span class="description"> We use your data to: </span>
        <div class="list-container">
          <li>
            Verify your identity and usage of the services we provide to you
          </li>
          <li>
            Fulfill contractual obligations and University of the Pacific
            requests
          </li>
          <li>Provide support and updates regarding your services with us</li>
          <li>
            Contact you regarding regarding updates and services we think you
            may like
          </li>
          <li>Verify qualifications of prospective customers</li>
          <li>Manage billing and payment University Credit™ services</li>
          <li>Improve the user’s experience of the our site</li>
          <li>To enforce our terms and conditions</li>
          <li>To improve and maintain our services</li>
          <li>
            To secure and maintain your University Credit™ online accounts
          </li>
          <li>
            To secure your client information and maintain your University of
            the Pacific accounts
          </li>
          <li>To comply with applicable laws or legal processes</li>
          <li>To monitor activities in or related to our Services</li>
          <li>Request information on University Credit™ and its products and services</li>
          <li>Subscribe to our newsletter</li>
          <li>Sign up for a University Credit™ webinar</li>
        </div>
        <span class="heading"> With whom does University Credit™ share your data? </span>
        <span class="heading--small"> Sharing Your Information </span>

        <span class="description">
          Be assured that we do not sell any of the information collected from
          you to any third party. We may, however, engage third party agents in
          providing our services to you. For example, in providing customer
          service and marketing assistance, analyzing data, website analysis,
          sending email, and processing orders with University of the Pacific,
          Benerd College. Specifically, Transact-CashNet, our payments
          processor, and University of the Pacific, our partnered educational
          institution, have such access when processing your purchases. However,
          they are strictly prohibited from sending you marketing offers related
          to their own products and services. Additionally, we may disclose
          third parties’ aggregated, anonymous information for traffic analysis
          regarding the Site (although no personally identifiable data).
        </span>
        <span class="heading">
          University Credit™ shares your data with third parties as follows:
        </span>
        <div class="list-container">
          <li>Through the normal use of our Site or Services</li>
          <li>
            With your company contact based information provided during our
            registration process
          </li>
          <li>
            With University Credit™ services provides, such as fraud prevention or security
            service providers that us the information to help prevent, detect
            and resolve any malicious activity
          </li>
          <li>
            With relevant law enforcement officials, such as auditor or
            investigators to investigate fraud or malicious activity
          </li>
        </div>
        <span class="description">
          University Credit™, in addition to the information above, may also share information
          that cannot be reasonably used to identify your or your company.
        </span>
        <span class="heading"> Links to Other Websites </span>
        <span class="description">
          Note that this Site may be linked to other websites. This privacy
          statement applies solely to information collected by this website.
          Your privacy is not guaranteed once leaving our Site. We don’t adhere
          to the privacy practices or any content of websites that this Site may
          be linked to. It is advised that you review the privacy practices of
          the websites you visit.
        </span>
        <span class="heading"> How does University Credit™ store your data? </span>
        <span class="description">
          University Credit™ contracts with third party services who process customer data on
          behalf of University Credit™ are prohibited from using your data on their own
          behalf.
        </span>
        <span class="heading"> Information Security </span>
        <span class="description">
          We have taken every precaution to protect our users’ information
          online and off-line. The most sensitive information is encrypted and
          protected with encryption software. The users’ information is
          restricted to our office and is accessed only by employees when
          necessary to perform a specific job responsibility via a
          password-protected login screen. The servers that store personally
          identifiable information are in a secure environment. However, we
          cannot diminish all security and privacy risks with Internet usage. We
          will not be liable for any damages that may result from an unforeseen
          security hazard or misappropriation.
        </span>
        <span class="description">
          California Educational Consultant Group, Inc. (CECG), it’s
          subsidiaries and partners securely collect cardholder data (credit and
          debit cards) through e-commerce platforms. Card Holder Data is
          securely processed, stored and disposed by CECG for payment
          transactions at University of the Pacific, Benerd College. CECG
          adheres to the Payment Card Industry Data Security Standards (PCIDSS).
        </span>
        <span class="heading"> How does University Credit™ secure your data? </span>
        <span class="description">
           We utilize administrative, physical, and technical security
          measures and protocols to protect your personal and business
          information.
        </span>
        <span class="heading"> Information Security </span>
        <span class="description">
          We have taken every precaution to protect our users’ information
          online and off-line. The most sensitive information is encrypted and
          protected with encryption software. The users’ information is
          restricted to our office and is accessed only by employees when
          necessary to perform a specific job responsibility via a
          password-protected login screen. The servers that store personally
          identifiable information are in a secure environment. However, we
          cannot diminish all security and privacy risks with Internet usage. We
          will not be liable for any damages that may result from an unforeseen
          security hazard or misappropriation.
        </span>
        <span class="heading"> Updating Our Policy </span>

        <span class="description">
          We will update this policy as necessary and to stay compliant with
          relevant laws. Any revised version will be indicated with a revised
          date and posted on the Site. The updated policy will then be in
          effect.
        </span>
        <span class="heading"> How can you contact University Credit™? </span>
        <span class="description">
          Questions and Reviewing, Updating or Deleting Personal Information For
          questions concerning this Privacy Policy or if you wish to review,
          update, or delete any personal information you have provided to us,
          please contact: admin@universitycredit.com
        </span>
      </div>
    </template>
    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button variant="success" @click="cancel"> Close </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  // watch: {
  //   stage(newVal) {
  //     if (newVal === "success" || newVal === "error") {
  //       this.$emit("closeDialog");
  //       this.$root.$emit("bv::hide::modal", "terms-and-condition-modal");
  //     }
  //   },
  // }
};
</script>


<style scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.list-style-circle {
  list-style-type: circle;
}
.heading {
  font-size: 18px;
  font-weight: 500;
}
.heading--big {
  font-size: 26px;
  font-weight: bold;
}
.description {
  font-size: 16px;
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.list-container {
  padding: 10px 20px;
  font-size: 16px;
}
.footer {
  text-align: center;
}
</style>